import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";

import AppRoutes from "AppRoutes";
import AppHead from "AppHead";
import AppProviders from "AppProviders";
import GlobalStyles from "templates/global-styles";
import ScrollToTop from "templates/scroll-to-top";

import "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import ChannelTalk from "templates/channel-talk";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={client}>
        <ChannelTalk>
          <AppHead />
          <GlobalStyles />
          <AppProviders>
            <Router>
              <ScrollToTop />
              <AppRoutes />
            </Router>
          </AppProviders>
        </ChannelTalk>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
