import React, { useEffect } from "react";

import * as ChannelService from "@channel.io/channel-web-sdk-loader";

type Props = {
  children: React.ReactNode;
};

function ChannelTalk({ children }: Props) {
  useEffect(() => {
    const pluginKey = process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY;

    if (!pluginKey) return;
    try {
      ChannelService.loadScript();

      ChannelService.boot({ pluginKey });
    } catch (error) {
      console.error("채널톡 초기화 중 오류:", error);
    }

    return () => {
      ChannelService.shutdown();
    };
  }, []);

  return <>{children}</>;
}

export default ChannelTalk;
